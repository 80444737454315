const NordRedBox3 = () => (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#tu271dkqva)">
            <path d="M140 0H0v140h140V0z" fill="#8C1A11" />
            <path d="M79.23 90.614h-4.609v27.267h4.61V90.614z" fill="#fff" />
            <mask id="rhetm523ab" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="14" y="84" width="119" height="34">
                <path d="M132.329 84.605H14.699v33.276h117.63V84.605z" fill="#fff" />
            </mask>
            <g mask="url(#rhetm523ab)" fill="#fff">
                <path d="M78.385 93.962c2.892-2.396 5.275-3.28 9.03-3.349 4.058-.073 6.713.887 9.784 3.537l-2.257 4.045c-1.863-2.54-4.095-3.64-7.244-3.574-4.607.096-8.554 3.662-8.554 8.151l-.76-8.81zM119.024 84.605h-4.515v33.276h4.515V84.605zM24.631 104.778v13.105h-4.61V90.616h4.61l-.026 3.958c2.703-2.858 5.59-4.003 9.716-3.959 6.712.071 11.959 4.542 13.109 11.158v16.11h-4.172v-14.511c-.577-4.826-4.455-8.267-9.314-8.279-5.423-.014-9.174 3.886-9.362 10.283l.05-.595v-.003z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M47.395 104.17c0-7.507 6.086-13.594 13.593-13.594s13.594 6.087 13.594 13.594-6.087 13.593-13.594 13.593-13.593-6.086-13.593-13.593zm4.233 0a9.36 9.36 0 1 1 18.721 0 9.36 9.36 0 0 1-18.721 0zM91.272 104.207c0-7.507 6.087-13.594 13.594-13.594s13.594 6.087 13.594 13.594-6.087 13.593-13.594 13.593-13.594-6.086-13.594-13.593zm4.234 0a9.36 9.36 0 1 1 18.72 0 9.36 9.36 0 0 1-18.72 0zM123.645 87.342v-.98h.699c.294-.015.654.058.701.418-.045.529-.412.56-.84.56h-.56v.002zm0 .418h.56l.84 1.4h.559l-.98-1.4c.522-.089.881-.34.839-.98.042-.567-.331-.845-1.119-.84h-1.261v3.22h.56v-1.4h.002zm-1.679-.28c0-1.265.99-2.257 2.239-2.238 1.279-.02 2.272.973 2.239 2.239.033 1.387-.96 2.373-2.239 2.38-1.249-.007-2.241-.993-2.239-2.38zm2.239 2.8c1.536.034 2.829-1.139 2.799-2.8.03-1.545-1.263-2.72-2.799-2.66-1.505-.06-2.799 1.115-2.799 2.66 0 1.661 1.294 2.834 2.799 2.8z" />
            </g>
        </g>
        <defs>
            <clipPath id="tu271dkqva">
                <path fill="#fff" d="M0 0h140v140H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default NordRedBox3;