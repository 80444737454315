const NordRedBox4 = () => (
    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#lxhow9ftfa)">
            <path d="M75 0H0v75h75V0z" fill="#8C1A11" />
            <path d="M42.445 48.543h-2.47V63.15h2.47V48.543z" fill="#fff" />
            <mask id="1olkzg8e9b" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="7" y="45" width="64" height="19">
                <path d="M70.89 45.325H7.875V63.15H70.89V45.325z" fill="#fff" />
            </mask>
            <g mask="url(#1olkzg8e9b)" fill="#fff">
                <path d="M41.992 50.338c1.55-1.284 2.826-1.758 4.838-1.794 2.173-.04 3.596.475 5.241 1.895l-1.21 2.167c-.997-1.36-2.193-1.95-3.88-1.915-2.468.051-4.582 1.962-4.582 4.367l-.407-4.72zM63.763 45.325h-2.42V63.15h2.42V45.324zM13.195 56.132v7.02h-2.47V48.546h2.47l-.014 2.12c1.448-1.53 2.995-2.144 5.205-2.12 3.596.038 6.407 2.433 7.023 5.977v8.63h-2.235V55.38c-.31-2.585-2.387-4.429-4.99-4.435-2.905-.008-4.915 2.081-5.015 5.508l.026-.318v-.002z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M25.39 55.806a7.283 7.283 0 1 1 14.565 0 7.283 7.283 0 0 1-14.565 0zm2.268 0a5.015 5.015 0 1 1 10.029 0 5.015 5.015 0 0 1-10.03 0zM48.896 55.826a7.283 7.283 0 1 1 14.565 0 7.283 7.283 0 0 1-14.565 0zm2.268 0a5.015 5.015 0 1 1 10.029 0 5.015 5.015 0 0 1-10.03 0zM66.239 46.79v-.524h.374c.157-.008.35.031.375.224-.024.283-.22.3-.45.3h-.3zm0 .225h.3l.45.75h.3l-.526-.75c.28-.048.472-.183.45-.525.022-.304-.178-.453-.6-.45h-.676v1.725h.3v-.75h.002zm-.9-.15c0-.678.53-1.21 1.2-1.2a1.16 1.16 0 0 1 1.199 1.2c.017.744-.514 1.271-1.2 1.275-.669-.004-1.2-.531-1.199-1.275zm1.2 1.5a1.444 1.444 0 0 0 1.499-1.5c.016-.828-.677-1.458-1.5-1.425a1.43 1.43 0 0 0-1.499 1.425c0 .89.693 1.518 1.5 1.5z" />
            </g>
        </g>
        <defs>
            <clipPath id="lxhow9ftfa">
                <path fill="#fff" d="M0 0h75v75H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default NordRedBox4;